import { createSelector } from '@ngrx/store';

import {
  getCurrentUser,
  getCurrentTenant,
  getIsUserAdmin,
  getCurrentAccountType,
  getPrimaryAccountType,
} from '@features/auth';
import { IUserSidebarInfoModel } from '@features/navigation/models';
import { SidebarAdditionalAccountTypesEnum } from '@features/navigation/enums';
import { generateUserFullName } from '@shared/utils';
import { ACCOUNT_TYPES_LABEL } from '@shared/constants';
import { AccountTypeEnum } from '@shared/enums';

export const getProfileSidebarInfo = createSelector(getCurrentUser, getCurrentTenant, (user, company) =>
  user && company
    ? {
        picture: user.picture,
        fullName: generateUserFullName(user.name),
        company: company.name,
      }
    : ({} as IUserSidebarInfoModel)
);

export const getProfileFullName = createSelector(getCurrentUser, (user) =>
  user ? generateUserFullName(user.name) : ''
);

export const getSidebarAccountType = createSelector(getIsUserAdmin, getCurrentAccountType, (isUserAdmin, accountType) =>
  isUserAdmin ? SidebarAdditionalAccountTypesEnum.ADMIN : accountType
);

export const getSwitchAccountTabs = createSelector(getPrimaryAccountType, (primaryAccountType) => [
  {
    value: primaryAccountType === AccountTypeEnum.BROKER ? AccountTypeEnum.BROKER : AccountTypeEnum.BORROWER,
    title:
      ACCOUNT_TYPES_LABEL[
        primaryAccountType === AccountTypeEnum.BROKER ? AccountTypeEnum.BROKER : AccountTypeEnum.BORROWER
      ],
  },
  {
    value: AccountTypeEnum.LENDER,
    title: ACCOUNT_TYPES_LABEL[AccountTypeEnum.LENDER],
  },
]);
