<notification
  class="error-handler__notification"
  *ngIf="error$ | async"
  [text]="error$ | async"
  [closable]="true"
  [spacing]="false"
  (hide)="onHide()"
  [style.margin-top.px]="spacingTop"
  [style.margin-bottom.px]="spacingBottom"
></notification>
