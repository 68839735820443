import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';

import { ErrorHandlerService } from '@features/error-handler/services';
import { Router } from '@angular/router';
import { DOMService } from '@app/shared/services';

@Component({
  selector: 'error-handler-container',
  templateUrl: './error-handler-container.component.html',
  styleUrls: ['./error-handler-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorHandlerContainerComponent implements OnDestroy, OnInit {
  private postNavigationError: string = null;

  @Input()
  public spacingBottom = null;

  @Input()
  public spacingTop = null;

  @Output()
  public errorToggle: EventEmitter<boolean> = new EventEmitter();

  public error$: Observable<string | null> = this.errorHandlerService.errorMessage$;

  constructor(
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly router: Router,
    private readonly domService: DOMService
  ) {
    this.error$.subscribe((message) => this.errorToggle.emit(message !== null));
    const navigation = this.router.getCurrentNavigation();

    // Post navigation error means we need to display error notification after navigating to any page.
    // Meaning some action in the previous page failed and we need to inform the user.
    this.postNavigationError = navigation?.extras?.state?.displayErrorPostNavigate;
  }

  public ngOnInit(): void {
    if (this.postNavigationError) {
      this.errorHandlerService.newError(this.postNavigationError);
      this.domService.scrollIntoErrorView();

      // Clean up the error after displaying it
      this.postNavigationError = null;
    }
  }

  public onHide() {
    event.stopPropagation();

    this.errorHandlerService.hide();
    this.errorToggle.emit(false);
  }

  public ngOnDestroy() {
    this.errorHandlerService.hide();
  }
}
